import classNames from 'classnames/bind'

import styles from './Footer.module.scss'

const cx = classNames.bind(styles)

function Footer() {
  return (
    <div className={cx('wrapper')}>
      <h1>Copyright © 2023 T Services</h1>
    </div>
  )
}

export default Footer
