const images = {
  vietnam: require('./vietnam.svg').default,
  tem: require('./tem.svg').default,
  product: require('./product.svg').default,
  user: require('./user.svg').default,
  teamScan: require('./teamScan.svg').default,
  scan: require('./scan.svg').default,
  vegetable: require('./vegetable.svg').default,
  fruit: require('./fruit.svg').default,
  dryfood: require('./dryfood.svg').default,
  frozen: require('./frozen.svg').default,
  water: require('./water.svg').default,
}

export default images
