import { Box, Tab, Tabs, Typography } from "@material-ui/core";
import TabPanel from "@mui/lab/TabPanel";
import classNames from "classnames/bind";
import { useEffect, useState } from "react";
import "slick-carousel/slick/slick-theme.css";
import TabContext from "@mui/lab/TabContext";
import "slick-carousel/slick/slick.css";
import SampleNextArrow from "../../components/SampleNextArrow";
import SamplePrevArrow from "../../components/SamplePrevArrow";
import styles from "./Product.module.scss";
import CustomCarousel from "./customSlide/custom.slider";
import { Paper } from "@mui/material";
import moment from "moment";
import { faWindows } from "@fortawesome/free-brands-svg-icons";
const queryString = require("query-string");

const cx = classNames.bind(styles);

function Product() {
  document.title = "Thông tin sản phẩm - TRoot";
  const queryParams = queryString.parse(window.location.search);
  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoPlay: true,
    autoplaySpeed: 2000,
    nextArrow: <SampleNextArrow />,
    prevArrow: <SamplePrevArrow />,
  };
  const [dataProd, setDataProd] = useState(null);
  var id = queryParams.id;
  var dem = 0;
  const [image, setImage] = useState([]);
  const [dataDN, setdataDN] = useState(null);
  const [dataQT, setDataQT] = useState(null);
  const [dataKD, setDataKD] = useState(null);
  useEffect(() => {
    fetch("https://api.nckh.sytuan.net:8080/api/getSPNoSig", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({ idsp: id }),
    })
      .then((response) => response.json())
      .then((data) => {
        if (data.length == 0) {
          window.location.href = "/notshow";
        } else {
          data[0].HinhQD = "https://image.banlait.com:3000/" + data[0].HinhQD;
          setDataKD(data);
        }
      })
      .catch((error) => console.error(error));
    fetch(`https://api.nckh.sytuan.net:8080/api/getSanPham/${id}`)
      .then((response) => response.json())
      .then((dataProd) => {
        if (dataProd.trangthai == false) {
          window.location.href = "/404";
        }
        setDataProd(dataProd);
        if (dataProd != null) {
          const img = [];
          if (dataProd.HinhAnhSP.hinh1 != "null") {
            img.push(dataProd.HinhAnhSP.hinh1);
          }
          if (dataProd.HinhAnhSP.hinh2 != "null") {
            img.push(dataProd.HinhAnhSP.hinh2);
          }
          if (dataProd.HinhAnhSP.hinh3 != "null") {
            img.push(dataProd.HinhAnhSP.hinh3);
          }
          setImage(img);

          fetch(`https://api.nckh.sytuan.net:8080/api/getCQ/${dataProd.IDDN}`)
            .then((response) => response.json())
            .then((dataCQ) => {
              setdataDN(dataCQ);
            })
            .catch((err) => {
              window.location.href = "/NotFound";
              throw new Error("Lỗi");
            });
        }
      })
      .catch((err) => {
        window.location.href = "/NotFound";
        throw new Error("Lỗi");
      });
    fetch(`https://api.nckh.sytuan.net:8080/api/getQuyTrinh/${id}`)
      .then((response) => response.json())
      .then((data) => {
        setDataQT(data);
      })
      .catch(() => {
        window.location.href = "/NotFound";
        throw new Error("Lỗi");
      });
  }, [id]);
  const [tabValue, setTabValue] = useState(2);
  const tabChange = (event, newValue) => {
    setTabValue(newValue);
  };
  return (
    <div className={cx("wrapper")}>
      <div className={cx("inner")}>
        <div className={cx("info")}>
          <div className={cx("info-img")}>
            <CustomCarousel>
              {image.map((image, index) => {
                return <img key={index} src={image} />;
              })}
            </CustomCarousel>
          </div>
          {dataProd != null && (
            <>
              <div className={cx("info-right")}>
                <Typography
                  className={cx("tensp")}
                  style={{ fontWeight: "bold", color: "3b3e66", fontSize: 30 }}
                >
                  Tên sản phẩm: {dataProd.TenSP}
                </Typography>
                <div
                  className="spch"
                  style={{
                    backgroundColor: "#5cbd2c",
                    height: "20px",
                    lineHeight: "21px",
                    width: "30%",
                    display: "flex",
                    justifyContent: "center",
                    borderRadius: "30px",
                  }}
                >
                  <Typography style={{ color: "white" }}>
                    Sản phẩm chính hãng
                  </Typography>
                </div>
                <Typography>
                  Ngày SX: {moment(dataProd.NgaySX).format("DD/MM/YYYY")}
                </Typography>
                <Typography>
                  Hạn SD: {moment(dataProd.ThoiHanSD).format("DD/MM/YYYY")}
                </Typography>
                <Typography>Loại SP: {dataProd.LoaiSP}</Typography>
              </div>
            </>
          )}
        </div>
        <Paper fullWidth>
          <TabContext value={tabValue} style={{ width: "100%" }}>
            <Box
              sx={{
                borderBottom: 1,
                borderColor: "divider",
                bgcolor: "background.paper",
              }}
              style={{ width: "100%" }}
            >
              <Tabs
                value={tabValue}
                onChange={tabChange}
                centered
                fullWidth
                aria-label="basic tabs example"
                style={{ width: "100%" }}
              >
                <Tab
                  label="Thông tin sản phẩm"
                  style={{ whiteSpace: "nowrap", width: "100%" }}
                  fullWidth
                />
                <Tab
                  label="Thông tin doanh nghiệp"
                  style={{ whiteSpace: "nowrap", width: "100%" }}
                  fullWidth
                />
                <Tab
                  label="Thông tin quy trình"
                  style={{ whiteSpace: "nowrap", width: "100%" }}
                  fullWidth
                />
              </Tabs>
            </Box>
            <TabPanel value={0} index={0}>
              {dataProd != null && (
                <>
                  <Typography
                    style={{ fontSize: 20, fontFamily: "Times New Roman" }}
                  >
                    Tên sản phẩm: {dataProd.TenSP}
                  </Typography>
                  <Typography>Loại sản phẩm: {dataProd.LoaiSP}</Typography>
                  <Typography>
                    Ngày sản xuất:{" "}
                    {moment(dataProd.NgaySX).format("DD/MM/YYYY")}
                  </Typography>
                  <Typography>
                    Hạn SD: {moment(dataProd.ThoiHanSD).format("DD/MM/YYYY")}
                  </Typography>
                  <Typography>Loại sản phẩm: {dataProd.LoaiSP}</Typography>
                  <Typography>Lô hàng: {dataProd.lo}</Typography>

                  <Typography
                    variant="body1"
                    dangerouslySetInnerHTML={{
                      __html: "Mô tả về sản phẩm:" + dataProd.MoTa,
                    }}
                  ></Typography>
                </>
              )}
            </TabPanel>
            <TabPanel value={1} index={1}>
              {dataDN != null && (
                <div className={cx("div_tong")}>
                  <div className={cx("left_trai")}>
                    {dataDN.logo == null ? (
                      <div>
                        <img
                          src={require("../../assets/images/buss.png")}
                          style={{ width: "100%" }}
                          alt="Doanh nghiệp không có logo"
                        ></img>
                      </div>
                    ) : (
                      <div>
                        <img
                          src={dataDN.logo}
                          style={{ width: "100%" }}
                          alt="Logo doanh nghiệp"
                        ></img>
                      </div>
                    )}
                  </div>
                  <div className={cx("left_phai")}>
                    {dataDN != null && (
                      <div>
                        <Typography
                          style={{ fontFamily: "Be Vietnam", fontSize: 25 }}
                        >
                          Xuất xứ bởi doanh nghiệp: {dataDN.TenCQ}{" "}
                        </Typography>
                        <hr></hr>
                        <br></br>
                        <br></br>
                        <Typography style={{ color: "#3b3e66" }}>
                          Thông tin liên hệ:
                        </Typography>
                        <Typography>
                          Địa chỉ doanh nghiệp: {dataDN.DiaChi}
                        </Typography>
                        <Typography>Số điện thoại: {dataDN.SDT}</Typography>
                      </div>
                    )}
                  </div>
                </div>
              )}
            </TabPanel>
            <TabPanel value={2} index={2}>
              {dataQT != null && (
                <div>
                  <Typography style={{ color: "rgba(59,62,102,.5)" }}>
                    Nhật ký sản xuất - Truy xuất nguồn gốc
                  </Typography>
                  <Typography
                    style={{ fontFamily: "Be Vietnam", fontSize: 20 }}
                  >
                    Sản phẩm có {dataQT.length} quy trình
                  </Typography>
                  <Typography style={{ color: "#3b3e66" }}>
                    Thông tin được doanh nghiệp ghi nhật ký trong quá trình sản
                    xuất và đảm bảo bởi công nghệ Blockchain
                  </Typography>
                  <hr></hr>
                  <br></br>
                  {dataQT.map((dt) => {
                    dem++;
                    return (
                      <div>
                        <Typography
                          style={{
                            fontWeight: "Be Vietnam",
                            fontSize: 18,
                            color: "red",
                          }}
                        >
                          Quy trình thứ: {dem}
                        </Typography>
                        <Typography>Tên quy trình: {dt.TenQuyTrinh}</Typography>
                        <Typography>Mô tả về quy trình: {dt.GhiChu}</Typography>
                        <Typography>
                          Thời gian ghi nhận vào hệ thống:{" "}
                          {moment(dt.ThoiGian).format("DD/MM/YYYY")}
                        </Typography>
                        <Typography>
                          Trạng thái xác thực quy trình bởi các cơ quan kiểm
                          định:{" "}
                          {dt.TTKy == true ? "Đã xác thực" : "Chưa xác thực"}
                        </Typography>
                        <br></br>
                        <br></br>
                      </div>
                    );
                  })}
                  <br></br>
                  <br></br>
                  {dataKD != null && dataKD.length > 0 && (
                    <>
                      <Typography
                        variant="body1"
                        style={{ color: "green", fontSize: 30 }}
                      >
                        Sản phẩm đã được ký xác nhận toàn bộ quy trình
                      </Typography>
                      <Typography variant="body1">Ảnh chứng nhận</Typography>
                      <br></br>
                      <img src={dataKD[0].HinhQD}></img>
                    </>
                  )}
                </div>
              )}
            </TabPanel>
          </TabContext>
        </Paper>
      </div>
    </div>
  );
}

export default Product;
