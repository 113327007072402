import classNames from 'classnames/bind'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Link } from 'react-router-dom'

import styles from './Header.module.scss'
import images from '../../assets/images'
import {
  faCaretDown,
  faImages,
  faStar,
} from '@fortawesome/free-solid-svg-icons'

const cx = classNames.bind(styles)

function Header() {
  return (
    <div className={cx('wrapper')}>
      <div className={cx('inner')}>
        <Link
          to="/"
          className={cx('logo')}
        >
          <img
            src={require('./logo.png')}
            alt="logo"
          />
        </Link>
        <div className={cx('translate')}>
          <img
            className={cx('translate-img')}
            src={images.vietnam}
          />
          <div className={cx('dropdown')}>
            <ul>
              <li>
                <img src={images.vietnam} />
                <span>Tiếng Việt</span>
              </li>
              <li>
                <img src={require('./english.png')} />
                <span>Tiếng Anh</span>
              </li>
              <li>
                <span>Ngôn ngữ khác</span>
              </li>
              <li>
                <select>
                  <option>Chọn ngôn ngữ</option>
                  <option>Tiếng Hàn</option>
                  <option>Tiếng Nhật</option>
                  <option>Tiếng Pháp</option>
                  <option>Tiếng Tây Ban Nha</option>
                  <option>Tiếng Ý</option>
                </select>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>
  )
}

export default Header
