import classNames from "classnames/bind";
import { useLocation } from "react-router-dom";
import queryString from "query-string";
import { Route, Routes } from "react-router-dom";

import Footer from "./components/Footer";
import Header from "./components/Header";
import Product from "./pages/Product";
import Home from "./pages/Home";
import { SnackbarProvider } from "notistack";
import LoiErrorNot from "./Loi404/LoiErrorNot";
import Avalible from "./pages/Product/Avalible";

function App() {
  let location = useLocation();
  const queryString = require("query-string");

  return (
    <div className="App">
      <SnackbarProvider
        maxSnack={3}
        anchorOrigin={{ horizontal: "right", vertical: "top" }}
      >
        <Routes>
          <Route path="/getSanpham" element={<Product />} />
          <Route path="/" element={<Home />} />
          <Route path="/NotFound" element={<LoiErrorNot />} />
          <Route path="/notshow" element={<Avalible />} />
        </Routes>
        <Header />

        <Footer />
      </SnackbarProvider>
    </div>
  );
}

export default App;
