import { useState } from 'react'
import styles from './Button.module.scss'

function Button({ children, borderColor }) {
  const [bgColorDefault, setBgColorDefault] = useState('white')
  return (
    <button
      style={{
        border: `1px solid ${borderColor}`,
        backgroundColor: bgColorDefault,
      }}
      onClick={() => setBgColorDefault(borderColor)}
      onBlur={() => setBgColorDefault('white')}
    >
      {children}
    </button>
  )
}

export default Button
