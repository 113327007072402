import classNames from 'classnames/bind'
import { useEffect, useState } from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import Slider from 'react-slick'
import { Link, Navigate, useNavigate } from 'react-router-dom'

import 'slick-carousel/slick/slick.css'
import 'slick-carousel/slick/slick-theme.css'
import { faMagnifyingGlass } from '@fortawesome/free-solid-svg-icons'
import images from '../../assets/images'
import SamplePrevArrow from '../../components/SamplePrevArrow'
import SampleNextArrow from '../../components/SampleNextArrow'
import Button from '../../components/Button'

import styles from './Home.module.scss'
import { useSnackbar } from 'notistack'

const cx = classNames.bind(styles)

const products = [
  {
    image: require('./demo-product.jpeg'),
    name: 'NutiMilk 100% sữa New',
  },
  {
    image: require('./demo-product2.png'),
    name: 'MT VAJRA Advance',
  },
  {
    image: require('./demo-product3.jpg'),
    name: 'Thịt Vai Heo Thảo Mộc',
  },
  {
    image: require('./demo-product4.jpg'),
    name: 'Hạt tiêu đỏ sấy hữu cơ',
  },
  {
    image: require('./demo-product5.jpg'),
    name: 'Thịt heo Thảo mộc',
  },
  {
    image: require('./demo-product6.png'),
    name: 'Nước năng lượng hữu cơ ',
  },
  {
    image: require('./demo-product7.jpg'),
    name: 'Thanh long Tầm Vu',
  },
  {
    image: require('./demo-product8.jpg'),
    name: 'Dầu Qủa Bơ',
  },
  {
    image: require('./demo-product9.png'),
    name: 'Dưa lưới',
  },
  {
    image: require('./demo-product10.jpg'),
    name: 'Mít',
  },
  {
    image: require('./demo-product11.png'),
    name: 'Gạo ST25',
  },
  {
    image: require('./demo-product12.jpg'),
    name: 'Khoai sáp vàng hữu cơ',
  },
]

function Home() {
  document.title = "Truy xuất sản phẩm - TRoot";
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();
  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 9,
    slidesToScroll: 4,
    autoPlay: true,
    autoplaySpeed: 2000,
    nextArrow: <SampleNextArrow />,
    prevArrow: <SamplePrevArrow />,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 5,
          slidesToScroll: 1,
          infinite: true,
          autoPlay: true,
          autoplaySpeed: 2000,
          dots: true,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 4,
          slidesToScroll: 1,
          initialSlide: 2,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  }

  const [idProduct, setIDProduct] = useState(null)
  const navigate = useNavigate();
  var link = `getSanpham?id=${idProduct}`
  return (
    <div className={cx('content')}>
      <div className={cx('introduce')}>
        <div className={cx('introduce-main')}>
          <div className={cx('first')}>
            <span className={cx('introduce-heading')}>
              TRoot - Nền tảng Blockchain cho việc truy xuất nguồn gốc sản phẩm
            </span>
            <a
              className={cx('introduce-view')}
              href="https://truyxuatnguongoc.sytuan.net"
            >
              <span
                style={{
                  fontWeight: 700,
                  lineHeight: '24px',
                  fontSize: '14px',
                }}
              >
                Truy cập website
              </span>
            </a>
            <form
              className={cx('introduce-search')}
              onSubmit={(e) => {
                e.preventDefault()
                setIDProduct('')
              }}
            >
              <FontAwesomeIcon icon={faMagnifyingGlass} />
              <input
                value={idProduct}
                onChange={(e) => {
                  setIDProduct(e.target.value)
                }}
                placeholder="Nhập mã sản phẩm để tìm thông tin sản phẩm"
              />
              <Link
                onClick={() => {
                  
                    if(idProduct == null) {
                     enqueueSnackbar(
                       "ID sản phẩm đang trống",
                       { variant: "error" }
                     );
                     return ;
                    }
                    else{
                      window.location.href = link;
                    }
                    
                    
                  
                }}
              >
                Tìm kiếm
              </Link>
            </form>
            <span className={cx('introduce-error')}>
              
            </span>
          </div>
        </div>
        <div className={cx('introduce-img')}>
          <div className={cx('img-inner')}>
            <div className={cx('img-main')}>
              <div className={cx('introduce-scan')}>
                <div className={cx('scan-inner')}>
                  <img src={images.scan} />
                </div>
              </div>
              <img src={images.teamScan} />
            </div>
          </div>
        </div>
      </div>
      <div className={cx('product')}>
        <div className={cx('inner')}>
          <div className={cx('product-heading')}>
            <h2>Sản phẩm nổi bật</h2>
            <div className={cx('product-tabs')}>
              <Button borderColor="#d2eedc">
                <img src={images.vegetable} />
                <span
                  style={{
                    fontWeight: 700,
                    lineHeight: '24px',
                    fontSize: '14px',
                  }}
                >
                  Rau củ
                </span>
              </Button>
              <Button borderColor="#ffd8d0">
                <img src={images.fruit} />
                <span
                  style={{
                    fontWeight: 700,
                    lineHeight: '24px',
                    fontSize: '14px',
                  }}
                >
                  Trái cây
                </span>
              </Button>
              <Button borderColor="#ffe2ad">
                <img src={images.dryfood} />
                <span
                  style={{
                    fontWeight: 700,
                    lineHeight: '24px',
                    fontSize: '14px',
                  }}
                >
                  Đồ khô
                </span>
              </Button>
              <Button borderColor="#d8ccfe">
                <img src={images.frozen} />
                <span
                  style={{
                    fontWeight: 700,
                    lineHeight: '24px',
                    fontSize: '14px',
                  }}
                >
                  Đông lạnh
                </span>
              </Button>
              <Button borderColor="#bdebff">
                <img src={images.water} />
                <span
                  style={{
                    fontWeight: 700,
                    lineHeight: '24px',
                    fontSize: '14px',
                  }}
                >
                  Nước
                </span>
              </Button>
            </div>
          </div>
        </div>
        <div className={cx('product-list')}>
          <div className={cx('inner')}>
            <Slider {...settings}>
              {products.map((product, index) => (
                <div
                  key={index}
                  className={cx('product-item')}
                >
                  <div className={cx('product-img')}>
                    <img src={product.image} />
                  </div>
                  <span className={cx('product-name')}>{product.name}</span>
                </div>
              ))}
            </Slider>
          </div>
        </div>
      </div>
    </div>
  )
}

export default Home
