import React from 'react';
import PropTypes from 'prop-types';

LoiErrorNot.propTypes = {
    
};

function LoiErrorNot(props) {
    document.title = "Not Found - TRoot";
    return (
        <div>
            <h1 style={{marginTop: '8%'}}>Not Found</h1>
        </div>
    );
}

export default LoiErrorNot;