import React from "react";
import PropTypes from "prop-types";

Avalible.propTypes = {};

function Avalible(props) {
    document.title = "Sản phẩm chưa được ký hoàn tất - TRoot"
  return (
    <div>
      <h1 style={{ marginTop: "5%" }}>
        Sản phẩm chưa được hiển thị vì chưa xác nhận quy trình cuối cùng
      </h1>
    </div>
  );
}

export default Avalible;
