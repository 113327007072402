function SamplePrevArrow(props) {
  const { className, style, onClick } = props
  return (
    <div
      className={className}
      style={{
        ...style,
        background: 'green',
        borderRadius: '88px',
      }}
      onClick={onClick}
    />
  )
}

export default SamplePrevArrow
